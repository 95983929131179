.properties {
	.page-header {
		height: 208px;
	}
	.page-content {
		position: relative;
		top: -70px;
	}

	.spectrum-table {
		.col-propertyId {
			width: 11%;
		}
		.col-streetAddress {
			width: 20%;
		}
		.col-dealType {
			width: 10%;
		}
		.col-exchangeNumber {
			width: 10% !important;
		}
		.col-exchanger {
			width: 15%;
		}
		.col-escrowCompanyName {
			width: 14%;
		}
		.col-lastModificationTime {
			width: 8%;
		}

		.col-actions {
			width: 6% !important;
		}
		.icon-notepad:before {
			font-size: 17px;
		}
		.icon-notepad {
			top: 2px;
			position: relative;
			left: -4px;
		}
		.col-actions-header {
			.tale-head-tittle {
				text-align: left;
			}
		}
	}
}
.properties {
	.form-lable {
		padding-bottom: 5px;
	}
}
.react-datepicker-wrapper {
	width: 100%;
}
.w-25 {
	width: 25% !important;
}

.exchange-listing-page .spectrum-table-th {
	overflow: hidden !important;
	white-space: break-spaces !important;
	text-overflow: inherit !important;
}
.note-icon-span {
	padding: 5px;
	position: relative;
	cursor: pointer;
	margin-right: 5px;
	float: right;
	background-color: rgba(218, 231, 246, 0.31);
	border-radius: 100%;
	height: 33px;
	width: 33px;
	.icon-notes {
		top: 4px;
		position: relative;
		left: -3px;
	}
	.icon-lock,
	.icon-unlock {
		position: relative;
		top: 3px;
		left: -6px;
	}
}
.note-icon-span .icon-notes:before {
	font-size: 17px;
}

.col-actions .listing-actions {
	display: inline-block;
	width: 33px;
}
.close-btn-round-slider {
	right: 13px !important;
	margin-top: -4px !important;
}
.left-item {
	float: left;
}
.note-text textarea {
	font-size: 13px;
	color: #bababa;
	background: #fafcff;
	border: 1px solid #d5dfef;
	border-radius: 7px;
	border-radius: 7px;
}

.note-text .form-button-light {
	padding-right: 0;
}
.note-text .form-button-apply {
	padding-right: 3px;
}
.note-text .note-block {
	font-size: 13px;
	color: #bababa;
	background: #fafcff;
	border: 1px solid #d5dfef;
	border-radius: 7px;
	min-height: 75px;
	padding: 11px;
	margin-top: 15px;
	.content {
		font-size: 13px;
		color: #21314b;
		letter-spacing: 0.19px;
	}
	.author {
		font-size: 12px;
		color: rgba(0, 52, 123, 0.78);
		letter-spacing: 0.17px;
		padding-top: 8px;
	}
	.date {
		font-size: 12px;
		color: #939dbc;
		letter-spacing: 0;
		padding-left: 5px;
	}
	.delete {
		opacity: 0.31;
		background: rgba(15, 86, 166, 0.2);
	}
	.close-btn-delete {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 0 5px;
		cursor: pointer;
		font-size: 16px !important;
		position: relative;
		float: right;
		top: -4px;
		background: rgba(108, 158, 216, 0.31);
	}
	.form-button-secondary {
		width: 125px;
	}
}
