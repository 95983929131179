.pdf-configuration-form {
	.info-card-body {
		padding-bottom: 15px !important;
	}
	.tox-tinymce {
		height: 300px !important;
		width: 96%;
		margin: auto;
	}
}
