.entity-list-page {
	.page-header {
		height: 208px;
	}
	.page-content {
		position: relative;
		top: -70px;
	}

	.spectrum-table {
		.col-entity-name {
			width: 28%;
		}

		.col-entity-type {
			@media screen and (min-width: 1200px) {
				word-break: break-word !important;
			}

			width: 12%;
		}
		.col-state {
			width: 12%;
		}

		.col-city {
			width: 12%;
		}
		.col-status {
			width: 12%;
		}
		.col-date {
			width: 12%;
		}
		.col-actions {
			width: 12%;
		}
	}
}
