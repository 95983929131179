.bank-page {
	.custom-radio {
		height: 20px;
		width: 20px;
		.checkmark {
			height: 20px;
			width: 20px;
			margin: 0;
		}
	}
	.spectrum-table {
		.col-bank-choose {
			width: 15%;
			display: flex;
		}
		.col-bank-name {
			width: 15%;
		}
		.col-address {
			width: 15%;
		}
		.col-city {
			width: 15%;
		}
		.col-state {
			width: 15%;
		}
		.col-zip {
			width: 15%;
		}
		.col-actions {
			width: 10%;
		}
	}
}
