.login-wrap {
	height: 100vh;
}
.session-content-loader {
	height: 100%;
	background-image: linear-gradient(90deg, rgba(21, 94, 179, 0.86) 0%, rgba(0, 13, 86, 0.87) 99%);

	.session-bg-gradient {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-image: url("../../assets/images/login-bg-image.jpg");
		background-position: 100% 0;
		background-repeat: no-repeat;
		pointer-events: none;
		max-width: 100%;
		max-height: 100%;
		text-align: center;
		object-fit: contain;
		background-position: center;
	}
}

.session-bg-gradient {
	opacity: 0.2;
}
.sign-in-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
	.forgot {
		max-height: 450px !important;
		.login-logo {
			height: 36% !important;
		}
	}
	.reset-password {
		max-height: 584px !important;
	}
	.sign-in-container {
		background: #ffffff;
		box-shadow: 1px 3px 6px 5px rgba(0, 0, 0, 0.16);
		border-radius: 7px;
		border-radius: 7px;
		max-width: 386px;
		height: 100vh;
		max-height: 500px;
		position: relative;
		background-color: var(--color-white);
		margin: 10px;
		.login-logo {
			position: relative;
			width: 100%;
			height: 27%;
			z-index: 0;
			background-image: url("../../assets/images/login-logo.png");
			background-position: 100% 0;
			background-repeat: no-repeat;
			pointer-events: none;
			max-width: 100%;
			max-height: 100%;
			text-align: center;
			object-fit: contain;
			background-position: center;
		}
	}
}
.forgot-password {
	font-size: 12px;
	color: var(--page-heading-color);
	letter-spacing: 0;
	font-weight: 700;
}
.forgot-password:hover {
	color: var(--page-heading-color);
}
.password-hint {
	margin-left: 20px;
	cursor: pointer;
}
