.pdfViewCard {
	canvas {
		margin: auto;
		border: 1px solid #000;
	}
	.annotationLayer {
		display: none;
	}
}
.pdf-viewer {
	background: #f3f6fb;
	// width: 96% !important;
	margin: auto;
	// background-color: var(--content-area-bg-color);

	.spectrum-pagination-one,
	.spectrum-pagination-two {
		display: none;
	}
	.spectrum-pagination-three {
		margin: auto;
	}

	.error,
	.loading {
		padding: 1rem;
		text-align: justify;
		padding-bottom: 0;
	}
	.loading {
		text-align: center;
	}
}
