.table-header {
	height: 50px;
	background-color: rgba(235, 239, 247, 0.41);
	width: 100%;
}
.add-button {
	text-align: right;
	padding-right: 25px;
	padding-bottom: 5px;
}
.task-table {
	box-shadow: none !important;
	padding-top: 0 !important;
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}
.table-row {
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.table-row:last-child {
	border-bottom: none !important;
}
.task-table {
	.col-transactionDate {
		width: 10%;
	}
}
.task-table {
	.col-propertyAddress {
		.ppty-deleted {
			color: red;
		}
	}
}
