.exchange-detail-page {
	.page-header {
		height: 175px;
	}
	.page-content {
		top: -50px;
		position: relative;
	}
	.time-line .start-img .label {
		top: -37px;
	}
	.time-line .end-img .label {
		top: -15px;
	}
}
.pr-0,
.px-0 {
	padding-right: 0 !important;
}
.bank-tab {
	border-radius: 0.5rem !important;
	box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.05);
	margin-bottom: 30px !important;
}
.bank-tab .tab-content {
	width: 100% !important;
	padding-bottom: 10px;
}
.bank-tab nav {
	padding: 0;
	margin: 0;
	background-color: #f3f6fb;
	border: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border: 0;
}
.exchange-top-info {
	margin-top: 15px;
}
.info-section-edit {
	height: 45px;
	.d-flex {
		margin-top: 10px;
	}
}
.nav-item.nav-link {
	padding: 10px !important;
}
