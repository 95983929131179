.notification-dropdown-menu {
	top: 58px;
	position: absolute;
	z-index: 500;
	min-width: 10rem;
	max-width: 23rem;
	margin-right: 65px;
	width: 357px;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 7px;
	border-top: 4px solid #72c5e5;
	animation: slideInDown 1s forwards;

	&:before {
		content: "";
		position: absolute;
		right: 122px;
		top: -15px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 20px 14px 20px;

		border-color: transparent transparent #72c5e5 transparent;
		z-index: 9998;
	}
	.view-all-btn,
	.view-all-btn:hover {
		width: 77px;
		height: 23px;
		font-size: 12px;
		padding: 3px 12px;
		margin: 15px 0;
	}
	.empty-notif {
		padding: 3px 12px;
		margin-bottom: 15px;
		margin-top: 5px;
		text-align: center;
		color: black;
	}
	.clear-notif {
		position: absolute;
		right: 15px;
		top: 15px;
		font-size: 13px;
	}
	.task-table {
		.table-head {
			padding: 15px !important;
			font-size: 14px;
			color: #232c56;
		}

		.table-row {
			padding-left: 17px;
			padding-right: 17px;
		}
		.table-col {
			padding: 0;
			display: flex;
			.row-icon {
				width: 8%;
				padding-right: 25px;
				margin: auto;
			}
			.message {
				width: 92%;
				line-height: 20px;
				font-size: 13px;
				text-align: left;
			}
		}
	}
}
// .notification-dropdown-menu:before {
// 	content: "";
// 	position: absolute;
// 	right: 122px;
// 	top: -9px;
// 	width: 0;
// 	height: 0;
// 	border-style: solid;
// 	border-width: 0 23px 14px 23px;

// 	border-color: transparent transparent #ffffff transparent;
// 	z-index: 9998;
// }
