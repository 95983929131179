.task-page {
	.spectrum-table {
		.col-task-name {
			width: 25%;
		}
		.col-exchange-type {
			width: 25%;
		}
		.col-description {
			width: 25%;
		}
		.col-actions {
			width: 25%;
		}
	}
}
