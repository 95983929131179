@media screen and (min-width: 768px) and (max-width: 1000px) {
	/*dashboard*/
	.dashboard-management {
		.display-flex {
			flex-wrap: wrap;
		}
		.exchange-summary-wrap {
			.exchange-summary-card {
				flex: 1 0 45%;
				border: 0 !important;
			}
			.exchange-action-card {
				flex: 22%;
			}
		}

		.recent-item-wrap,
		.revenue-escrows-wrap,
		.activity-sales-wrap {
			flex-wrap: wrap;
			.recent-exchange-card,
			.next-event-card,
			.revenue-card,
			.escrows-card,
			.activity-card,
			.top-sales-card {
				flex: 100%;
			}
		}
	}

	.task-table {
		.head-item {
			vertical-align: top;
			padding-right: 5px;
		}
		/*wid*/
		.col-exchange-number {
			width: 12% !important;
		}
		.col-exchanger {
			width: 18% !important;
		}
		.col-exchange-type {
			width: 14% !important;
		}
		.col-day-in-exchange {
			width: 13% !important;
		}
		.col-last-milestone {
			width: 19% !important;
		}
		.col-fee-due {
			width: 12% !important;
		}
		.col-pending-tasks {
			width: 11% !important;
		}
		/*wid*/
	}
	/*dashboard*/

	/*exchange*/
	.spectrum-table {
		display: inline-grid !important;
		overflow-x: scroll !important;
	}
	.summary-card-wrap {
		.summary-card {
			&:last-of-type {
				.item {
					margin-right: 15px;
				}
			}
		}
	}
	.keyword-search {
		.keyword-search-container {
			max-width: 355px;
			float: unset;
		}
	}

	/*table*/
	.exchange-listing-page {
		.spectrum-table {
			.col-exchangeNumber {
				width: 14% !important;
			}
			.col-status {
				width: 11% !important;
			}
			.col-Exchanger {
				width: 11% !important;
			}
			.col-ExchangeType {
				width: 8%;
			}
			.col-openDate {
				width: 10%;
			}

			.col-accountBalance {
				width: 20% !important;
			}
			.col-actions {
				width: 5% !important;
			}
		}
	}
	.spectrum-table {
		.spectrum-table-th:not(.nowrap),
		.spectrum-table-td:not(.nowrap) {
			overflow: visible !important;
		}
	}

	/*table*/
	/*exchange*/

	/*properties*/
	/*table*/

	.exchange-listing-page {
		.spectrum-table {
			.col-propertyId {
				width: 18%;
			}
			.col-streetAddress {
				width: 14%;
			}
			.col-dealType {
				width: 12%;
			}
			.col-lastModificationTime {
				width: 15%;
			}
			// .col-actions{
			//     width: 7%;
			// }
		}
	}
	/*table*/
	/*properties*/

	/*transactions*/
	/*table*/
	.exchange-listing-page {
		.spectrum-table {
			.col-escrowCompany {
				width: 13% !important;
			}
			.col-transactionDate {
				width: 17% !important;
			}
			/*table income*/
			.col-category {
				width: 10%;
			}
			/*table income*/
		}
	}
	/*table*/
	/*transactions*/

	/*people*/
	.user-list {
		.spectrum-table {
			.col-exchangerName {
				width: 23%;
			}
			.col-exchangerType {
				width: 14%;
			}
			.col-status {
				width: 13%;
			}
			/*escrow*/
			.col-EscrowName {
				width: 19%;
			}
			.col-settlementCompany {
				width: 24%;
			}
			.col-EscrowemailAddress {
				width: 21%;
				// overflow: hidden!important;
				word-break: break-all;
			}
			.col-EscrowcreationTime {
				width: 18%;
			}
			/*escrow*/

			/*contact*/
			.col-user-name {
				width: 13%;
			}
			.col-roleName {
				width: 21%;
			}
			.col-contactExchangerName {
				width: 18%;
			}
			.col-email {
				width: 25%;
				// overflow: hidden!important;
				word-break: break-all;
			}
			/*contact*/
		}
	}
	/*people*/

	/*report*/
	.w-16 {
		width: 31% !important;
		flex: unset !important;
	}
	/*report*/

	/*settings*/
	/*users*/
	.user-list {
		.spectrum-table {
			.col-jobTitle {
				width: 9%;
			}
			.col-date {
				width: 18%;
			}
			.col-actions {
				width: 11%;
			}
		}
	}

	/*users*/

	/*configuration*/
	.settings-card {
		flex: 0 0 47%;
	}
	/*configuration*/
	/*settings*/
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
	/*dashboard*/
	.dashboard-management {
		.display-flex {
			flex-wrap: wrap;
		}
		.exchange-summary-wrap {
			.exchange-summary-card {
				flex: 1 0 45%;
			}
			.exchange-action-card {
				flex: 22%;
			}
		}

		.recent-item-wrap,
		.revenue-escrows-wrap,
		.activity-sales-wrap {
			flex-wrap: wrap;
			.recent-exchange-card,
			.next-event-card,
			.revenue-card,
			.escrows-card,
			.activity-card,
			.top-sales-card {
				flex: 100%;
			}
		}
	}

	.task-table {
		.head-item {
			vertical-align: top;
			padding-right: 5px;
		}
		/*wid*/
		.col-exchange-number {
			width: 12% !important;
		}
		.col-exchanger {
			width: 16% !important;
		}
		.col-exchange-type {
			width: 15% !important;
		}
		.col-day-in-exchange {
			width: 15% !important;
		}
		.col-last-milestone {
			width: 17% !important;
		}
		.col-fee-due {
			width: 11% !important;
		}
		.col-pending-tasks {
			width: 12% !important;
		}
		/*wid*/
	}
	/*dashboard*/
	/*exchange*/
	.spectrum-table {
		display: inline-grid !important;
		overflow-x: scroll !important;
	}
	.summary-card-wrap {
		.summary-card {
			&:last-of-type {
				.item {
					margin-right: 15px;
				}
			}
		}
	}
	/*table*/
	.exchange-listing-page {
		.spectrum-table {
			.col-exchangeNumber {
				width: 14% !important;
			}

			.col-accountBalance {
				width: 20% !important;
			}
			.col-status {
				width: 11% !important;
			}
		}
	}
	.spectrum-table {
		.spectrum-table-th:not(.nowrap),
		.spectrum-table-td:not(.nowrap) {
			overflow: visible !important;
		}
	}

	/*table*/
	/*exchange*/

	/*properties*/
	/*table*/
	.exchange-listing-page {
		.spectrum-table {
			.col-propertyId {
				width: 18%;
			}
			.col-streetAddress {
				width: 14%;
			}
			.col-dealType {
				width: 12%;
			}
			.col-lastModificationTime {
				width: 16%;
			}
			.col-actions {
				width: 7% !important;
			}
		}
	}
	/*table*/
	/*properties*/

	/*transactions*/
	/*table*/
	.exchange-listing-page {
		.spectrum-table {
			.col-escrowCompany {
				width: 13% !important;
			}
			.col-accountNumber {
				width: 15%;
			}
			.col-transactionDate {
				width: 17% !important;
			}
			/*table income*/
			.col-category {
				width: 10%;
			}
			/*table income*/
		}
	}
	/*table*/
	/*transactions*/

	/*people*/
	.user-list {
		.spectrum-table {
			/*escrow*/
			.col-EscrowName {
				width: 19%;
			}
			.col-settlementCompany {
				width: 24%;
			}
			.col-EscrowemailAddress {
				width: 21%;
				// overflow: hidden!important;
				word-break: break-all;
			}
			.col-EscrowcreationTime {
				width: 18%;
			}
			/*escrow*/
		}
	}
	/*people*/
	/*report*/
	.w-16 {
		width: 31% !important;
		flex: unset !important;
	}
	/*report*/

	/*settings*/

	/*user*/
	.user-list {
		.spectrum-table {
			.col-email {
				width: 25%;
				// overflow: hidden!important;
				word-break: break-all;
			}
		}
	}

	/*user*/
	/*configuration*/
	.settings-card {
		flex: 0 0 31%;
	}
	/*configuration*/
	/*settings*/
}
@media screen and (max-width: 1300px) {
	/*report*/
	.w-16 {
		width: 31% !important;
		flex: unset !important;
	}
	/*report*/
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
	/*transactions*/
	/*table*/
	.exchange-listing-page {
		.spectrum-table {
			/*table income*/
			.col-category {
				width: 10%;
			}
			/*table income*/
		}
	}
	/*table*/
	/*transactions*/

	.spectrum-table {
		.spectrum-table-th:not(.nowrap),
		.spectrum-table-td:not(.nowrap) {
			overflow: visible !important;
		}
	}
}

@media screen and (min-width: 1200px) {
	.spectrum-table {
		.spectrum-table-th:not(.nowrap),
		.spectrum-table-td:not(.nowrap) {
			overflow: visible !important;
		}
	}
}
