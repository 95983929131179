@keyframes table-anim {
	from {
		transform: scale(0.98, 0.98);
		opacity: 0;
	}
	to {
		transform: scale(1, 1);
		opacity: 1;
	}
}
.spectrum-table {
	color: #002756;
	display: flex;
	width: 100%;
	flex-direction: column;
	animation: table-anim 0.4s ease-in-out;
	.text-highlighted {
		color: #002756;
	}
	.spectrum-table-col-group {
		display: table-column-group;
		.spectrum-table-col {
			display: table-column;
		}
	}
	.spectrum-table-thead {
		display: flex;
		font-weight: 600;
		flex: 1;
		.spectrum-table-tr {
			border-radius: 5px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 700;
			color: rgba(11, 27, 127, 0.54);
			min-height: 40px;
			width: 100%;
			margin: 0;

			.spectrum-table-th:first-child {
				border-radius: 5px 0 0 5px;
			}
			.spectrum-table-th:last-child {
				border-radius: 0 5px 5px 0;
			}
			.sortable {
				cursor: pointer;
				.icon {
					display: inline-block;
					width: 10px;
					height: 10px;
					margin-top: 3px;
					.icon-descen-sort,
					.icon-ascen-sort {
						&.path2:before {
							margin-left: -8px !important;
						}
					}
				}
			}
		}
	}
	.spectrum-table-tbody {
		display: flex;
		flex-direction: column;
		font-size: 14px;

		.spectrum-table-tr {
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			transition: all 0.1s ease-in-out;
			min-height: 50px;
			background-color: #fff;
			&:hover {
				box-shadow: 0 2px 4px rgba(66, 195, 212, 0.3);
			}
			.spectrum-table-td:first-child {
				border-radius: 5px 0 0 5px;
				padding-left: 15px;
			}
			.spectrum-table-td:last-child {
				border-radius: 0 5px 5px 0;
			}
		}
		.spectrum-table-td {
			&:not(.nowrap) {
				word-break:break-word;
			}
		}
	}
	.spectrum-table-tr {
		display: flex;
		flex: 1;
		margin: 0 0 10px 0;
		align-items: center;
	}
	.spectrum-table-th {
		display: block;
		padding: 10px 15px;
		letter-spacing: 1px;
		&.break-word {
			white-space: inherit !important;
		}
	}
	.spectrum-table-td {
		display: block;
		padding: 5px;
	}
	.spectrum-table-th,
	.spectrum-table-td {
		&:not(.nowrap) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.spectrum-table-td-placeholder {
		padding: 8px;
		border-radius: 3px;
		// background-color: rgba(0, 0, 0, 0.06);
		// text-align: center;
		display: block;
	}
}

.spectrum-pagination {
	padding-top: 10px;
	display: flex;
	width: 100%;
	align-items: center;
	font-weight: 500;
	font-size: 12px;
	// color: rgba(12, 45, 99, 0.98);
	color: rgba(12, 45, 99, 0.5);
	margin: 7px 0px;
	.spectrum-pagination-one {
		flex: 1;
	}
	.spectrum-pagination-two {
		flex: 1;
		text-align: right;
		justify-content: flex-end;
	}
	.spectrum-pagination-three {
		// flex: 1;
		display: flex;

		.pagination-btn {
			cursor: pointer;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;

			&.current {
				color: rgba(12, 45, 99, 0.98);
				border-radius: 100%;
				background-color: #fff;
				font-weight: 600;
			}
			&.disabled {
				opacity: 0.4;
				pointer-events: none;
			}
		}
		.pagination-prev,
		.pagination-next {
			font-size: 15px;
			color: #5ca9ff;
			font-weight: 600;
		}
	}
}
.col-actions {
	flex: 1 1;
}
.col-actions .listing-actions {
	float: right;
}
.per-page {
	font-size: 12px !important;
	letter-spacing: 0.3px !important;
	text-decoration: none !important;
	font-weight: 400;
	cursor: pointer;
	margin: 5px;
	padding: 5px;
}
.active-count,
.per-page:hover {
	color: rgba(12, 45, 99, 0.98);
	border-radius: 100%;
	background-color: #fff;
	font-weight: 600;
}
.no-data-table {
	text-align: center;
	padding-top: 25px;
	background-color: #f9f9f9;
	padding-bottom: 25px;
}

.spectrum-table-td {
	overflow: hidden !important;
	white-space: break-spaces !important;
	text-overflow: inherit !important;
}
.tale-head-tittle {
	padding-top: 0;
	margin-top: 0;
	margin-left: 5px;
	padding-right: 5px;
}
.spectrum-table-th.d-flex.col-actions {
	flex-direction: row-reverse !important;
}
