body {
	margin: 0;
	font-family: "Rubik";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	overflow-y: hidden;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.side-panel {
	width: 100px;
	background-color: blue;
	height: 100vh;
}
.sub-title {
	margin-bottom: 20px;
}
.slider {
	position: absolute;
	right: 0px;
	width: 350px;
	margin: 0;
	.modal-content {
		height: 100vh;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
	.slider-body {
		padding: 0;
		display: flex;
		flex: 1;
		.slider-form {
			flex: 1;
			display: flex;
			flex-direction: column;
			.slider-form-fields {
				//padding: 0 20px;
				padding: 10px 20px 0px 20px;
			}
			.form-button {
				padding: 10px 20px;
				box-shadow: 0 -1px 4px 1px rgba(150, 150, 150, 0.12);
			}
		}
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.notification-container {
	box-sizing: border-box;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999999;
	width: auto;
	padding: 0 15px;
	max-height: calc(100% - 30px);
	overflow-x: hidden;
	overflow-y: auto;
}
.notification-container > span {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.notification-container .notification {
	box-shadow: none;
	padding: 12px 15px 12px 58px;
	font-size: 12px;
	margin-bottom: 0;
	margin-top: 15px;
	background-color: rgba(0, 0, 0, 0.7);
	opacity: 1;
	border-radius: 4px;
}
.notification-container .notification-error:before {
	color: #ff3b31;
}
.notification:before {
	color: #5ced5c;
}
.notification-message {
	font-weight: 600;
	font-size: 13px;
}
.app_loader {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.8;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
}
.spinner {
	position: absolute;
	left: 48%;
	top: 50%;
	height: 60px;
	width: 60px;
	margin: 0px auto;
	-webkit-animation: rotation 0.6s infinite linear;
	-moz-animation: rotation 0.6s infinite linear;
	-o-animation: rotation 0.6s infinite linear;
	animation: rotation 0.6s infinite linear;
	border-left: 6px solid rgba(0, 174, 239, 0.15);
	border-right: 6px solid rgba(0, 174, 239, 0.15);
	border-bottom: 6px solid rgba(0, 174, 239, 0.15);
	border-top: 6px solid rgba(0, 174, 239, 0.8);
	animation: spinner-border 0.75s linear infinite;
	border-radius: 100%;
}

.spinner-text {
	position: absolute;
	left: 46%;
	top: 65%;
	height: 60px;
	margin: 0px auto;
	font-size: large;
	color: #e9ecef;
}

h1 {
	font-size: 26px !important;
	color: var(--color-white) !important;
	letter-spacing: 0 !important;
	font-weight: 700 !important;
	margin-bottom: 0 !important;
}

h2 {
	font-size: 16px;
	color: var(--page-heading-color);
	letter-spacing: 0 !important;
	font-weight: 700 !important;
	margin-bottom: 0 !important;
}
.heading {
	h2 {
		font-size: 14px;
	}
}
.sub-heading {
	margin-top: 30px;
	h3 {
		color: var(--page-subhead-color) !important;
		font-size: 14px;
		margin-bottom: 0 !important;
	}
}

.heading-hr {
	background: var(--heading-hr-color) !important;
	width: 35px !important;
	height: 3px !important;
	margin-top: 12px !important;
	float: left !important;
	border-top: 0;
	margin-bottom: 0px;
}
.sub-heading-hr {
	background: var(--heading-hr-color) !important;
	width: 25px !important;
	height: 2px !important;
	margin-top: 10px !important;
	float: left !important;
	border-top: 0;
	margin-bottom: 0px;
}
.form-lable {
	font-size: 13px !important;
	color: var(--page-label-color) !important;
	letter-spacing: 0 !important;
	font-weight: 700;
	padding-bottom: 10px;
}

.form-field {
	background: var(--color-white);
	border: 1px solid var(--page-input-border-color);
	color: var(--page-input-color);
	box-shadow: none;
	height: 40px;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 7px;
	padding-top: 7px;
	font-size: 14px;
	width: 100%;
	&:read-only {
		background: #f6f9f8;
	}
}
.form-field:focus {
	border: 1px solid var(--page-input-border-color) !important;
	outline: none !important;
}

.form-button-primary,
.form-button-primary:focus,
.form-button-primary:active {
	background-image: var(--button-primary-bg) !important;
	box-shadow: 0 2px 6px 1px rgba(42, 120, 227, 0.33) !important;
	border-radius: 5px !important;
	height: 40px !important;
	border: 0 !important;
	color: var(--color-white) !important;
	outline: none !important;
	background-color: transparent !important;
}

.form-button-primary:hover:not(.inactive) {
	background-image: var(--button-primary-bg-hover) !important;
}
.content-center {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.form-error-span {
	color: var(--error-text-color);
	font-size: 12px;
	font-weight: 500;
}
.form-error {
	border: 1px solid var(--error-text-color) !important;
}
.not-matching {
	border: 1px solid var(--error-text-color) !important;
}
.custom-radio {
	display: inline-block;
	position: relative;
	cursor: pointer;
	user-select: none;
	height: 25px;
	width: 25px;
	input {
		position: absolute;
		opacity: 0;
		height: 25px;
		width: 25px;
		cursor: pointer;
		&:checked ~ .checkmark {
			.radio-bullet {
				background-color: #61a9fc;
			}
		}
	}
	.checkmark {
		cursor: pointer;
		position: absolute;
		height: 20px;
		width: 20px;
		background-color: #fff;
		border-radius: 50%;
		border: solid #61a9fc 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		.radio-bullet {
			border-radius: 50%;
			height: 10px;
			width: 10px;
		}
	}
}
.custom-check {
	display: inline-block;
	position: relative;
	cursor: pointer;
	user-select: none;
	height: 25px;
	// width: 25px;
	input {
		position: absolute;
		opacity: 0;
		height: 25px;
		width: 25px;
		cursor: pointer;
		&:checked ~ .checkmark {
			&:after {
				display: block;
			}
		}
	}
	.checkmark {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		border: solid #204880 1px;
		border-radius: 5px;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 8px;
			top: 2px;
			width: 8px;
			height: 16px;
			border: solid #204880;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.nav-tabs {
	.nav-link {
		color: var(--page-label-color) !important;
		font-size: 12px;
		font-family: Rubik-Medium;
		&:hover {
			border: 1px solid transparent;
		}
		&.active {
			border: none;
			border-bottom: solid var(--heading-hr-color) 2px !important;
			color: #285f9a !important;
		}
	}
}
.card-header-tabs {
	margin-bottom: 30px !important;
	margin-left: 0 !important;
}
.col-head.label {
	font-size: 13px;
	color: rgba(81, 81, 81, 0.78);
	font-weight: 400;
}
// Animations
.slideInRight {
	animation-name: slideInRight;
	animation-duration: 1s;
}
@keyframes slideInRight {
	0% {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	to {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
}
.slideOutRight {
	animation-name: slideOutRight;
	animation-duration: 0.5s;
}

@keyframes slideOutRight {
	0% {
		transform: translateZ(0);
	}
	to {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
	}
}
.css-loader {
	width: 75px;
	height: 50px;
	background-image: url("./assets/images/css_sprites-1.png");
	animation: moveX 5s steps(18) infinite;
}

@keyframes moveX {
	from {
		background-position-x: 0px;
	}
	to {
		background-position-x: -1420.2px;
	}
}
