@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?g45odo');
  src:  url('fonts/icomoon.eot?g45odo#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?g45odo') format('woff2'),
    url('fonts/icomoon.ttf?g45odo') format('truetype'),
    url('fonts/icomoon.woff?g45odo') format('woff'),
    url('fonts/icomoon.svg?g45odo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-read-mail:before {
  content: "\e92a";
}
.icon-doc:before {
  content: "\e928";
  color: #5ca9ff;
}
.icon-excel:before {
  content: "\e929";
}
.icon-lock:before {
  content: "\e925";
  color: #a5a5a5;
}
.icon-notepad:before {
  content: "\e926";
  color: #3dbcf1;
}
.icon-unlock:before {
  content: "\e927";
  color: #0eb610;
}
.icon-no-sort:before {
  content: "\e920";
  color: #a9a9a9;
}
.icon-ascen-sort .path1:before {
  content: "\e921";
  color: rgb(92, 169, 255);
}
.icon-ascen-sort .path2:before {
  content: "\e922";
  margin-left: -0.5830078125em;
  color: rgb(169, 169, 169);
}
.icon-descen-sort .path1:before {
  content: "\e923";
  color: rgb(169, 169, 169);
}
.icon-descen-sort .path2:before {
  content: "\e924";
  margin-left: -0.5830078125em;
  color: rgb(92, 169, 255);
}
.icon-exchange-1:before {
  content: "\e91f";
}
.icon-dashboard:before {
  content: "\e91d";
  color: #c0c0c0;
}
.icon-Notification:before {
  content: "\e91e";
  color: #5ca9ff;
}
.icon-eye:before {
  content: "\e91a";
}
.icon-eye-hide:before {
  content: "\e91b";
}
.icon-bank:before {
  content: "\e916";
  color: #024da1;
}
.icon-dollar:before {
  content: "\e917";
  color: #024da1;
}
.icon-entities:before {
  content: "\e918";
  color: #024da1;
}
.icon-pdf:before {
  content: "\e919";
  color: #024da1;
}
.icon-template:before {
  content: "\e91c";
  color: #024da1;
}
.icon-tick:before {
  content: "\e914";
  color: #024da1;
}
.icon-close:before {
  content: "\e915";
  color: #8f8f8f;
}
.icon-icon-change-password:before {
  content: "\e911";
  color: #024da1;
}
.icon-icon-delete:before {
  content: "\e912";
  color: #024da1;
}
.icon-icon-edit:before {
  content: "\e913";
  color: #024da1;
}
.icon-Left-Arrow:before {
  content: "\e90e";
}
.icon-Right-Arrow:before {
  content: "\e90f";
}
.icon-settings:before {
  content: "\e910";
  color: #c0c0c0;
}
.icon-calendar:before {
  content: "\e900";
  color: #024da1;
}
.icon-down-arrow:before {
  content: "\e901";
}
.icon-filter:before {
  content: "\e902";
  color: #6c9ed8;
}
.icon-menu-exchangers:before {
  content: "\e903";
}
.icon-menu-exchanges:before {
  content: "\e904";
  color: #fff;
}
.icon-menu-properties:before {
  content: "\e905";
  color: #c0c0c0;
}
.icon-menu-reports:before {
  content: "\e906";
  color: #c0c0c0;
}
.icon-menu-templates:before {
  content: "\e907";
  color: #c0c0c0;
}
.icon-menu-transactions:before {
  content: "\e908";
  color: #c0c0c0;
}
.icon-menu-users:before {
  content: "\e909";
  color: #c0c0c0;
}
.icon-more:before {
  content: "\e90a";
  color: #6c9ed8;
}
.icon-notes:before {
  content: "\e90b";
  color: #6c9ed8;
}
.icon-search:before {
  content: "\e90c";
  color: #6c9ed8;
}
.icon-up-arrow:before {
  content: "\e90d";
}
