.admin-details {
	.filter {
		display: flex;
		align-items: flex-end;
		margin-bottom: 15px;
		.form-group {
			margin-right: 20px;
			margin-bottom: 0;
		}
		button.form-button-primary {
			span::before {
				color: #fff !important;
			}
		}
		// .icon-btn {
		// 	bottom: 3px;
		// }
	}
	.image-upload-input {
		display: none;
	}
	.page-content {
		.card {
			.info-card-body {
				.profile-pic-container {
					width: 100px;
					position: relative;
					margin-right: 15px;
					display: flex;
					flex-direction: column;
					align-items: center;
					.profile-pic-wrap {
						width: 100px;
						height: 100px;
						border-radius: 50%;
						display: inline-block;
						background: var(--content-area-bg-color);
						border: 1px solid #cacedf;
						overflow: hidden;

						.profile-avatar {
							width: 100px;
						}
					}
					.profile-confirm-block {
						margin-top: 9px;
						display: flex;
						justify-content: space-between;
						width: 59px;
						.icon-close,
						.icon-tick {
							cursor: pointer;
						}
					}
					.user-action-icon,
					.user-action-icon-close {
						position: absolute;
						border: 1px solid #cacedf;
						background: var(--content-area-bg-color);
						border-radius: 50%;
						width: 30px;
						height: 30px;
						display: inline-flex;
						font-size: 17px;
						justify-content: center;
						align-items: center;
						margin: 0 5px;
						cursor: pointer;
						left: 70px;
						top: 70px;
					}
					.user-action-icon-close {
						left: -6px !important;
						font-size: 12px;
					}
					.spinner {
						width: 30px;
						height: 30px;
						left: 0;
						top: 0px;
					}
				}
				.user-info-wrap {
					flex: 1;
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
	}
	.table-striped {
		font-family: Rubik-Regular;
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background-color: #f8fafe;
	}
}
