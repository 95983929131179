.fee-page {
	.page-content {
		margin-bottom: 70px;
	}
	.spectrum-table {
		.col-exchange-type {
			width: 25%;
		}
		.col-exchange-fee {
			width: 25%;
		}
		.col-property-fee {
			width: 20%;
		}
		.col-actions {
			width: 25%;
		}
	}
}
