@font-face {
	font-family: "Rubik-Medium";
	src: url("./assets/fonts/Rubik-Medium.ttf");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
@font-face {
	font-family: "Rubik-Regular";
	src: url("./assets/fonts/Rubik-Regular.ttf");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
body {
	margin: 0;
	font-family: "Rubik";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
}
body,
div,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: "Rubik";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
	--color-white: #fff;
	--color-black: #222;
	--color-body: #454f4e;
	--page-title-color: #ffffff;
	--page-heading-color: #232c56;
	--page-subhead-color: #04080d;
	--page-label-color: #939dbc;
	--page-input-color: #21314b;
	--page-input-border-color: #a7b0cf;
	--page-input-inactive-color: #a4a6a6;
	--page-input-inactive-border-color: rgb(161, 161, 161, 0.29);
	--page-accordian-title-color: #232c56;
	--heading-hr-color: #5ca9ff;
	--content-area-bg-color: #f3f6fb;
	--top-section-background-image: linear-gradient(230deg, #001b6a, 0%, #00137e 99%);
	--top-section-background-pattern-color: #e2d3b0;
	--top-section-background-pattern-opacity: 12%;
	--button-primary-bg: linear-gradient(69deg, #3dbcf1 0%, #3386da 91%);
	--button-primary-bg-hover: linear-gradient(69deg, #6ac9f1 0%, #6ca6e2 91%);
	--error-text-color: #f42626;
	--a-color: #4898ec;
	--a-color-hover: #98b6d5;
	--button-primary-bg-inactive: #cbcccc;
	--button-secondary-color: #024da1;
	--button-secondary-color-hover: #1976df;
	--text-primary: #0f4083;
}
