.layout-wrapper {
	width: 100%;
}
.side-menu {
	width: 100px;
	background: #fff;
	height: 100vh;
	box-shadow: 0 2px 4px 0 rgba(9, 9, 9, 0.12);
}
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 85px;
	color: blue;
	font-weight: 600;
}
.menu-text {
	font-size: 11px;
	letter-spacing: 0.25px;
	margin-top: 5px;
	color: #002756;
	font-family: Rubik;
	font-weight: 400;
}
.menu-item {
	position: relative;
	width: 100%;
	height: 76px;
	.menu-link {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
	}
	& :hover {
		background-color: #e1f5fe;
		.menu-text {
			font-family: Rubik-Medium;
			color: #0f4083;
		}
		.menu-icon-wrap {
			background-color: rgba(61, 188, 241, 0.75);
		}
		.menu-icon {
			background: transparent;
			&:before {
				color: #fff !important;
				//font-size: 20px;
			}
		}
	}
}
.active-menu-item {
	background-color: #e1f5fe;
	.menu-icon-wrap {
		background-color: rgba(61, 188, 241, 0.75);
	}
	.menu-text {
		font-family: Rubik-Medium;
		color: #0f4083;
		margin-top: 10px;
	}
	.menu-icon::before {
		color: #fff !important;
		//font-size: 20px;
	}
}

.submenu {
	position: absolute;
	top: 0;
	left: 50px;
}
.nav-icon {
	position: absolute;
	top: 32px;
	left: 76px;
	font-size: 11px;
	&::before {
		color: #5ca9ff;
	}
}

.menu-icon-wrap {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #c0c0c0;
	font-size: 30px;
}
.menu-icon {
	line-height: 0 !important;
	&:before {
		color: #acacac !important;
		font-size: 25px;
	}
}

.page-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: var(--content-area-bg-color);
}
.title-header {
	margin-top: 0;
	.page-title {
		width: 100%;
	}
}
.page-header {
	padding: 10px 30px 30px 30px;
	background: url(../../../assets/images/BG-pattern.png), repeat;
	background-color: #001674;
	height: 150px;
	border-bottom-left-radius: 50px;
}
.menu-head {
	height: 40px;
}
.page-title {
	display: flex;
	justify-content: space-between;
}
.page-content {
	padding: 30px;
	.card {
		padding: 25px;
		margin-bottom: 15px;
		padding-bottom: 0;
		background: #ffffff;
		// box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.05);
		border: 1px solid rgba(104, 124, 193, 0.31);
		border-radius: 7px;
		.info-card-body {
			padding: 0px;
			display: flex;
			flex-wrap: wrap;
			padding-top: 0;
			padding-bottom: 0px;
			margin-bottom: 10px;
			.info-item {
				// width: 23%;
				// min-width: 150px;
				padding-left: 0;
				margin-bottom: 25px;
				// margin-right: 10px;
				.value {
					margin-top: 10px;
					font-size: 14px;
				}
			}
		}
		.info-card-title {
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			.user-action-icons {
				.user-action-icon {
					background: var(--content-area-bg-color);
					border-radius: 50%;
					width: 35px;
					height: 35px;
					display: inline-flex;
					font-size: 17px;
					justify-content: center;
					align-items: center;
					margin: 0 5px;
					cursor: pointer;
				}
			}
		}
	}
	.address-card {
		background: #fff;
		border: 1px solid #ebedf0;
		border-radius: 4px;
		border-radius: 4px;
		box-shadow: none;
		.button-primary {
			background-color: #3386da;

			/* Create the gradient. */
			background-image: linear-gradient(#3dbcf1, #3386da);

			/* Set the background size and repeat properties. */
			background-size: 100%;
			background-repeat: repeat;
			border: none;
			font-size: 14px;
			font-weight: 600;

			/* Use the text as a mask for the background. */
			/* This will show the gradient as a text color rather than element bg. */
			background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-text-fill-color: transparent;
		}
		.button-light {
			border: none;
			font-weight: 600;
			background: none;
			font-size: 13px;
			color: #989b9e;
		}
	}
}
.icon-top {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #5ca9ff;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: rgba(247, 247, 247, 0.13);
}
.icon-user {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #5ca9ff;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: rgba(247, 247, 247, 0.13);
}
.right-items {
	float: right;
	cursor: pointer;
}
.page-title h2 {
	font-size: 24px;
	color: #ffffff;
	letter-spacing: 0.71px;
	font-family: Rubik-Medium;
	margin-bottom: 15px;
}
.page-title hr {
	background: #5ca9ff;
	width: 40px;
	height: 4px;
	margin-top: 0;
	float: left;
}
.user-info-wrapper {
	margin-left: 10px;
}
.user-icon {
	margin-left: 18px;
}

.exchange-search {
	width: 24%;
	margin: 0 15px;

	.css-yk16xz-control {
		background-color: #f7f7f7;
		min-height: 35px !important;
	}
}
.notif-count-wrap {
	width: 22px;
	height: 22px;
	background-color: #ffffff;
	color: #4898ec;
	margin-left: 35px;
	margin-top: -25px;
	position: absolute;
	font-size: 11px;
	text-align: center;
	border-radius: 50%;
	padding: 6px 0;
	font-weight: bold;
}

.user-info {
	color: #fff;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.user-info .user-name,
.user-info .user-role {
	font-size: 12px;
}
.user-info {
	.user-name {
		text-transform: capitalize;
		font-weight: 600;
	}
	.user-name,
	.user-role {
		font-size: 12px;
	}
}
.menu-button {
	display: none;
}
.sidemenu-popover {
	left: -7px !important;
	border: none !important;
	.arrow {
		display: none !important;
	}
	.popover-body {
		width: 200px;
		box-shadow: 0 1px 4px rgba(9, 9, 9, 0.12);
		.submenu-item {
			display: flex;
			border-bottom: solid #f0f0f0 1px;
			align-items: center;
			padding: 10px 15px;

			color: #002756;
			font-family: Rubik;
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.3px;
			cursor: pointer;
			&:hover,
			&.active {
				background-color: #e1f5fe;
				/* Style for "Companies" */
				opacity: 1;

				color: #002756 !important;
				font-size: 13px;
				font-weight: 400;
				letter-spacing: 0.3px;
				.bullet {
					background-color: #66caf3;
				}
			}
			.bullet {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				display: inline-block;
				background-color: #c2c3c7;
				margin-right: 15px;
			}
		}
	}
}
.user-down-arrow {
	color: #fff;
	font-size: 11px;
	padding-left: 10px;
	padding-top: 9px;
	cursor: pointer;
}

.slide-menu-item {
	display: block;
	width: 100%;
	padding: 10px 20px;
	clear: both;
	font-weight: 400;
	color: #172843;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	&:hover {
		background: rgba(220, 234, 248, 0.76);
	}
}
.dropdown-divider {
	height: 0;
	margin: 0.8rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}
