.configurations-listing-page {
	.icon-notes::before {
		color: #024da1 !important;
	}
}
.settings-card {
	height: 104px;
	border-radius: 5px;
	margin-bottom: 15px;
	padding-left: 0 !important;
	padding-right: 0 !important;
	flex: 0 0 24%;
	// max-width: 25%;
	margin-right: 10px;
	-webkit-box-shadow: 0px 3px 19px -7px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 3px 19px -7px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 3px 19px -7px rgba(0, 0, 0, 0.75);
}
/*new*/
.flex--card {
	flex-wrap: wrap !important;
}
/*new*/
.round-button {
	display: inline-block;
}
@media (max-width: 768px) {
	.settings-card {
		width: 100% !important;
	}
}
@media (max-width: 576px) {
	.settings-card {
		width: 100% !important;
	}
}
.card1 {
	border-left: 8px solid #818ce2;
}
.card2 {
	border-left: 8px solid #b0636d;
}
.card3 {
	border-left: 8px solid #bbd182;
}
.card4 {
	border-left: 8px solid #5894c2;
}
.card5 {
	border-left: 8px solid #505aac;
}
.card6 {
	border-left: 8px solid #43c3b7;
}
.card-content {
	padding-left: 15px;
	background-color: #fff;
	margin-right: 15px;
	border-radius: 5px;
	padding-top: 15px;
	padding-bottom: 15px;
	min-height: 107px;
}
