.user-Info-table {
	.table-head,
	.row-head,
	.row-data {
		display: flex;
		padding: 4px 15px;
	}
	.user-table-row {
		border: 0px solid rgb(196, 210, 236);
		border-radius: 5px;
		margin-bottom: 10px;
		overflow: hidden;
		background: #ffffff;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
	}
	.row-head {
		background-color: rgb(235, 246, 254);
		height: 50px;
		align-items: center;
		.head-Item {
			.label {
				color: var(--page-label-color);
			}
			.sub-head {
				padding-left: 5px;
				font-size: 14px;
			}
			margin-right: 30px;
		}
	}
	.row-data {
		// border-bottom: 1px solid rgb(196, 210, 236);
		color: var(--page-label-color);
	}
}
.user-Info-table {
	width: 100%;
}
.row-data {
	min-height: 50px;
}
.user-table-col {
	margin-top: 0.5rem !important;
	color: #3c5e88;
	font-size: 14px;
}
.row-odd {
	background-color: #f8fafe;
}
.row-even {
	background-color: #fff;
}
