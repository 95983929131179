.user-details {
	.page-header {
		height: 200px;
	}
	.page-content {
		top: -75px;
		position: relative;
		.card {
			// padding: 25px;
			margin-bottom: 15px;
			.info-card-title {
				display: flex;
				justify-content: space-between;
			}
			.info-card-body {
				padding: 0px;
				display: flex;
				flex-wrap: wrap;
				.info-item {
					// width: 25%;
					// min-width: 150px;
					margin-bottom: 25px;
				}
			}
		}
	}
}
.form-button {
	button {
		float: right;
	}
}
.user-list {
	.spectrum-table {
		font-family: Rubik-Regular;
		.col-user-name {
			width: 20%;
		}
		.col-jobTitle {
			width: 10%;
		}
		.col-email {
			width: 20%;
			word-break: break-all;
		}
		.col-EscrowemailAddress {
			width: 20%;
			word-break: break-all;
		}
		.col-status {
			width: 10%;
		}
		.col-phone {
			width: 15%;
		}
		.col-date {
			width: 15%;
		}
		.col-actions {
			width: 10%;
		}
		.col-exchangerType {
			width: 16%;
		}
		.col-exchangerName {
			width: 28%;
		}
		.col-exchangerPhone {
			width: 16%;
		}
		.col-exchangerCrDate {
			width: 16%;
		}
		.col-EscrowName {
			width: 20%;
		}
		.col-settlementCompany {
			width: 20%;
		}
		.col-EscrowPhone {
			width: 13%;
		}
		.col-emailAddress {
			width: 16%;
			word-break: break-all;
		}
		.col-creationTime {
			width: 16%;
		}
		.col-EscrowcreationTime {
			width: 16%;
		}
		.col-roleName {
			width: 16%;
		}
		.col-contactExchangerName {
			width: 16%;
		}
	}
}
.col-description {
	width: 75%;
}
.col-createdOn {
	width: 25%;
}

.activity-log-search {
	width: 195px !important;
	min-width: 200px !important;
	padding-top: 28px;
}
.activity-log-search .keyword-search-container {
	max-width: 200px !important;
}
