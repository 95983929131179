.dropzone-wrap {
	.dropzone {
		border: 1px dashed;
		display: flex;
		// justify-content: center;
		padding: 22px 0;
		align-items: center;
		flex-direction: column;
		background-color: #f9fbff;
		height: 150px;
		border-radius: 4px;
		p {
			color: var(--page-label-color);
			line-height: 2px;
		}
		.btn-browse {
			color: #0f56a7;
			border: 1px solid #7da4d1;
			background-color: transparent;
		}
		.note {
			font-size: 12px;
			margin-top: 10px;
			line-height: 17px;
			text-align: center;
			padding: 0 15px;
			.icon-notes::before {
				color: #007bff;
			}
		}
	}
	.file-list {
		width: 100%;
		font-size: 14px;
		.file {
			border-bottom: 1px solid #e3e5ed;
			padding: 4px 0;
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			.file-item {
				flex: 1;
				overflow: hidden;
				display: flex;
				align-items: center;
				.file-name {
					max-width: calc(100% - 20px);
					margin-left: 6px;
					display: inline-block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					flex: 1;
				}
			}

			.delete-icon {
				cursor: pointer;
				background-color: #f1f5fa;
				border-radius: 50%;
				width: 25px;
				height: 25px;
				display: inline-flex;
				font-size: 14px;
				justify-content: center;
				align-items: center;
			}

			.rejected-item {
				color: var(--error-text-color);
				.icon-notes::before {
					color: var(--error-text-color);
				}
			}
		}
	}
}
