.dashboard-management {
	.text-bold {
		font-weight: bold;
	}
	.display-flex {
		width: 100%;
		display: flex;
	}
	.page-header {
		height: 200px;
	}
	.task-table {
		.table-row {
			display: flex;
			align-items: center;
		}
	}
	.page-content {
		position: relative;
		top: -70px;
		.card {
			margin-right: 1%;
			padding: 20px;
			::-webkit-scrollbar {
				width: 6px;
			}
			::-webkit-scrollbar-thumb {
				background: rgba(0, 0, 0, 0.2);
				border-radius: 3px;
			}
		}
	}
	.no-data-table {
		margin-top: 15px;
		margin-bottom: 24em;
	}
	.exchange-summary-wrap {
		.exchange-summary-card {
			flex: 0 0 37.3%;
			height: 127px;
			padding-bottom: 5px;
			border: 0;
			.count-wrap {
				display: flex;
				flex-direction: row;

				.flex-28 {
					flex: 0 0 29%;
				}
				.count-exchanges {
					p {
						color: #eba35a;
						font-size: 37px;
						font-weight: bold;
						margin: 0;
						line-height: 40px;
					}
				}
				img {
					width: 100%;
					height: 90%;
				}

				.count-active,
				.count-new {
					p {
						color: #24b7f4;
						font-size: 27px;
						font-weight: bold;
						line-height: 45px;
						margin: 0;
					}
				}
			}
			.active-exchanges {
				font-size: 12px;
				color: #a9a4a4;
				padding: 0px;
				margin: 0;
				position: absolute;
				top: 62%;
			}
			.progress {
				height: 6px;
				background: #a2c7ff;
				// margin-top: 15px;
				margin-top: 10px;
				.progress-bar {
					background: #8176d9;
				}
			}
			.exchanges-progress {
				color: #787878;
				display: inherit;
				font-size: 12px;
				width: 100%;
				.active-percent,
				.balance-percent {
					text-align: right;
				}
			}
			.exchange-balance {
				color: #48b756;
				font-size: 17px;
				font-weight: bold;
				width: 40%;
				height: 72px;
			}
			.transaction {
				margin-top: -20px;
				.rout-link {
					font-size: 11px !important;
					text-transform: uppercase;
				}
			}

			.funds-chart {
				width: 60%;
				height: 100px;
				margin-top: -25px;
				overflow-y: auto;
				overflow-x: hidden;

				canvas {
					width: 100% !important;
					height: 95% !important;
				}
			}
		}
		.exchange-action-card {
			flex: 0 0 7.1%;
			height: 127px;
			text-align: center;
			margin-bottom: 10px;
			line-height: 16px;
			padding: 20px 10px !important;
			cursor: pointer;
			img {
				margin: auto;
			}
			p {
				font-size: 11px;
			}
		}
	}
	.recent-item-wrap {
		width: 100%;
		display: flex;
		.recent-exchange-card {
			flex: 0 0 61%;
			height: 37em;

			.heading {
				padding-left: 20px;
			}
			padding: 20px 0px !important;
			.task-table {
				margin-top: 5px;

				.table-head {
					font-size: 11px;
				}
				.table-body {
					overflow-y: auto;
					height: 25em;
				}
				.table-row {
					.col-pending-tasks {
						text-align: center;
					}
					.col-last-milestone {
						line-height: 16px;
					}
					font-size: 13px;
					.exchanger-property {
						font-weight: bold;
					}
					.rout-link {
						font-size: 13px !important;
						color: #155091;
					}
				}
				.table-head,
				.table-row {
					padding-left: 20px;
					padding-right: 10px;
					min-height: 5em;
				}
			}
			.viewExchangesLink {
				float: right !important;
				padding-right: 20px;
				padding-top: 5px;
				font-size: 12px !important;
				font-weight: bold;
			}
		}
		.next-event-card {
			flex: 0 0 38%;
			height: 37em;
			.events {
				margin-top: 15px;

				.carousel-controls {
					position: absolute;
					right: 20px;
					top: 15px;
					.caro--Icon {
						padding: 7px 10px;
						border-radius: 50%;
						background: #f7f9fb;
						cursor: pointer;
						outline: none;
						border: none;
						img {
							opacity: 1;
						}
					}
					.caro--Icon:hover {
						background: #edf3f9;
						cursor: pointer;
					}
					.caro--Icon:disabled {
						background: #f7f9fb;
						cursor: not-allowed;
						img {
							opacity: 0.5;
						}
					}
					.prev {
						margin-right: 8px;
					}
				}

				.carousel {
					height: 29em;
					.carousel-indicators {
						bottom: -45px;
						margin: auto;
						li {
							border-radius: 50%;
							background-color: #898989;
							width: 7px;
							height: 7px;
							cursor: default;
						}
					}

					.carousel-control-next,
					.carousel-control-prev {
						display: none;
					}
					.carousel-inner {
						overflow-y: auto;
						height: 33em;
					}

					.Event__block {
						border: 1px solid #ebedef;
						border-radius: 5px;
						display: flex;
						margin-bottom: 4px;
						.Event__block--date {
							width: 11%;
							background: #fafcff;
							padding: 8px;
							font-size: 13px;
							text-align: center;
							.Dot_blue {
								background: #24b7f4;
								height: 8px;
								width: 8px;
								border-radius: 50%;
								margin: auto;
								margin-bottom: 10px;
							}

							.Event__block--num {
								font-weight: 500;
								font-size: 18px;
							}
						}

						.Event__block--item {
							width: 89%;
							padding: 4px 5px;
							font-size: 13px;
							line-height: normal;
							.rout-link {
								font-size: 12px !important;
								color: #155091;
							}
							ul {
								margin: 0;
								padding-left: 18px;
								// margin-top: 5px;
								// display: flex;
							}
							ul li {
								list-style-type: circle;
								float: left;
								line-height: 16px;
							}
							li::marker {
								font-size: 1.2em;
							}
							li:before {
								font-size: 20px;
							}
							li {
								width: 100%;
							}
							.Event__block--texter {
								font-size: 12px;
								margin-right: 25px;
								text-overflow: ellipsis;
								white-space: nowrap;
								width: 100%;
								overflow: hidden;
								span {
									color: #939dbc;
									font-weight: 500 !important;
								}
							}
						}
					}
				}
			}
		}
	}
	.revenue-escrows-wrap {
		width: 100%;
		display: flex;
		.revenue-card {
			flex: 0 0 61%;
			height: 29.8em;
			overflow-y: auto;
			padding-bottom: 0;
			.revenue-month {
				position: absolute;
				right: 20px;
				width: 16%;
			}
			.revenue-chart {
				margin-top: 12px;
				overflow-y: auto;
				height: 24.5em;

				canvas {
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
		.escrows-card {
			flex: 0 0 38%;
			height: 29.8em;
			padding: 20px 0px !important;

			.heading {
				padding-left: 20px;
				margin-bottom: 5px;
			}

			.task-table {
				.table-head {
					font-size: 11px;
				}
				.table-body {
					overflow-y: auto;
					height: 18.5em;
				}
				.table-row {
					padding-top: 12px;
					padding-bottom: 12px;
					font-size: 13px;
					.col-exchange--number {
						line-height: 16px;
					}
					.col-exchange--value {
						color: #50ba53;
					}
					.col-exchanges-count--value {
						color: #e95151;
					}
					.col-exchange--value,
					.col-exchanges-count--value {
						text-align: center;
						.value {
							width: 50%;
							margin: auto;
							padding: 2px 0px;
							border-radius: 10px;
							font-weight: bold;
							background: #edf2fb;
						}
						.clr-green {
							color: #50ba53;
						}
						.clr-red {
							color: #e95151;
						}
					}
				}
				.table-head,
				.table-row {
					padding-right: 10px;
				}
			}
		}
	}
	.activity-sales-wrap {
		width: 100%;
		display: flex;

		.activity-card {
			flex: 0 0 61%;
			height: 28.5em;

			.heading {
				margin-bottom: 15px;
			}
			.userActivities {
				overflow-y: auto;
			}
			ul.timeline {
				list-style-type: none;
				position: relative;
			}
			ul.timeline:before > li:last-child {
				height: 0% !important;
			}

			ul.timeline > li {
				padding-left: 10px;
				border-left: 1px solid #d4d9df;
				margin-left: -10px;
				padding-bottom: 1px;

				.day-time {
					color: #939dbc;
					font-size: 11px;
				}
				.exc-id {
					font-weight: bold;
					color: #155091;
				}
				&:last-child {
					border: none;
				}
				p {
					margin-top: 5px;
				}
			}
			ul.timeline > li:before {
				content: " ";
				background: #22c0e8;
				display: inline-block;
				position: absolute;
				border-radius: 50%;
				border: 3px solid #22c0e8;
				left: 25px;
				width: 10px;
				height: 10px;
				z-index: 400;
			}
		}
		.top-sales-card {
			flex: 0 0 38%;
			height: 28.5em;

			padding: 20px 0px !important;

			.heading {
				padding-left: 20px;
			}

			.task-table {
				margin-top: 5px;
				.table-head {
					font-size: 11px;
				}
				.table-body {
					overflow-y: auto;
					height: 18.5em;
				}
				.table-row {
					padding-top: 10px;
					padding-bottom: 10px;
					font-size: 13px;
					.col-representative {
						line-height: 16px;
					}
					.col-exchange-value {
						color: #50ba53;
					}
					.col-exchanges-count-value {
						color: #e95151;
					}
					.col-exchange-value,
					.col-exchanges-count-value {
						text-align: center;
						.value {
							width: 50%;
							margin: auto;
							padding: 2px 0px;
							border-radius: 10px;
							font-weight: bold;
							background: #edf2fb;
						}
						.clr-green {
							color: #50ba53;
						}
						.clr-red {
							color: #e95151;
						}
					}
				}
				.table-head,
				.table-row {
					padding-right: 10px;
				}
			}
		}
	}
}
