.document-tab {
	border-radius: 0.5rem !important;
	// box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.05);
	margin-bottom: 30px !important;

	.add-row {
		margin-bottom: 15px !important;
	}
	.col-propertyId {
		.ppty-deletd {
			color: red;
		}
	}

	.docx-status {
		display: inline-block;
		background: rgba(218, 231, 246, 0.31);
		border-radius: 12px;
		padding: 5px;
		padding-left: 10px;
		padding-right: 5px;
		font-weight: 700;
		font-size: 12px;
		min-width: 80px;
		// height: 28px;
		text-align: center;
	}
	.status-card {
		height: unset;
		width: 100%;
	}

	.status-1005,
	.status-1002 {
		color: #4fa0fb;
		background: rgba(79, 160, 251, 0.12);
	}
	.status-1001 {
		color: #e8c14b;
		background: rgba(232, 193, 75, 0.12);
	}
	.status-1003,
	.status-1004 {
		color: #1ac61c;
		background: rgba(6, 198, 28, 0.12);
	}

	.note-icon-span {
		padding: 7px 10px;
	}
	.col-actions {
		height: 25px;
	}
}
.document-pdf-modal {
	.modal-content {
		background: #fbfbfb;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
		border-radius: 3px;
		border: none;
		padding: 20px;
	}

	.modal-content {
		.modal-body {
			padding: 20px 0;
			font-size: 14px;
			color: #172843;
			letter-spacing: 0;
		}
		.modal-header {
			border: none;
			margin: 0;
			.close {
				outline: none;
				padding: 0;
				margin: 0px;
			}
		}
		.modal-footer {
			padding: 0;
			border: none;
		}
		.pdf-viewer {
			padding: 0;
		}
	}
}
