.time-line {
	display: flex;

	padding: 15px 20px;
	border-radius: 10px;
	position: relative;
	align-items: center;
	padding-bottom: 30px;
	.start-img,
	.end-img {
		position: relative;
		img {
			width: 40px;
		}
		.label {
			position: absolute;
			left: 0;
			width: 80px;
			color: #021a8b;
			font-size: 11px;
			letter-spacing: 0 !important;
			font-weight: 700 !important;
			margin-bottom: 0 !important;
		}
	}
	.end-img {
		top: -8px;
	}
	.time-progress {
		width: 100%;
		position: relative;
		background-color: #d9f2fd;
		height: 4px;
		display: flex;
		.progress-bar {
			background-color: #526ba7;
		}
	}
	.start-point {
		position: absolute;
		left: 0;
		top: -5px;
		background-color: #021a8b;
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}
	.point {
		position: absolute;
		display: inline-block;
		width: 20px;
		height: 20px;
		background: #fff;
		left: 0%;
		border-radius: 50%;
		top: -9px;
		border: 5px solid #3dbcf1;
		cursor: pointer;
		&.active {
			border: 7px solid #021a8b;
		}

		&:hover {
			.label {
				z-index: 1;
			}
		}
		.label {
			position: absolute;
			color: #021a8b;
			font-size: 11px;
			top: 25px;
			width: 200px;
			display: inline;
			background: white;
			padding: 0 5px;
			letter-spacing: 0 !important;
			font-weight: 700 !important;
			margin-bottom: 0 !important;
		}
	}
}
