.summary-card-wrap {
	margin-bottom: 15px;
	width: 100%;
	flex-wrap: wrap;
	display: flex;
	.summary-card {
		&:last-of-type {
			margin-right: 0;
			.item {
				margin-right: 0;
			}
		}
		.item {
			background: #fdfeff;
			box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.05);
			border-radius: 7px;
			height: 90px;
			min-width: 250px;
			padding: 20px;
			display: flex;
			margin-right: 15px;
		}
		.info {
			flex: 1;

			.label {
				font-family: Rubik-Medium;
				font-size: 14px;
				color: #00347b;
				letter-spacing: 0.2px;
				line-height: 17px;
			}
			.count {
				font-family: Rubik-Medium;
				font-size: 30px;
				color: #ce3929;
				letter-spacing: 0;
			}
		}
	}
}
