.progress-stage {
	.start-img,
	.end-img {
		cursor: pointer;
		display: flex;
		align-items: center;
		.stage-nav-text {
			color: #024da1;
			font-family: Rubik;
			font-size: 13px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: 0.19px;
			line-height: normal;
			text-align: left;
		}
		color: #024da1;
		&.disabled {
			pointer-events: none;
			.stage-nav-text {
				color: rgba(33, 49, 75, 0.4);
			}
			.stage-nav {
				color: #999da1;
				background-color: rgba(15, 86, 166, 0.2);
			}
		}
	}
	.start-img {
		margin-right: 15px;
	}
	.end-img {
		margin-left: 20px;
		top: 0;
	}
	.stage-nav {
		width: 33px;
		height: 33px;
		display: inline-flex;
		background-color: rgba(15, 86, 166, 0.2);
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}
	.stage-wrap {
		display: flex;
		width: 100%;
		position: absolute;
		justify-content: space-between;

		.point {
			position: relative;
			width: 35px;
			height: 35px;
			top: -15px;
			border: 4px solid #021a8b;
			color: #3dbbf1;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			&.active {
				background: #021a8b;
				color: #fff;
			}
			.label {
				background: transparent;
				position: absolute;
				width: 125px;
				top: 35px;
			}
		}
	}
}
.exchange-detail-page {
	.col-template-choose {
		width: 20%;
	}
	.col-template-name {
		width: 25%;
	}
	.col-template-modified {
		width: 20%;
	}
}
