.loader-hidden {
	display: none;
}
.loader-active {
	display: block;
}
.accordion > .card {
	overflow: unset;
}
.cursor-pointer {
	cursor: pointer;
}
.form-disabled {
	background: #f6f9f8 !important;
}

.app_loader .loader-inner {
	left: 50%;
	top: 50%;
	position: absolute;
}
.ball-clip-rotate-multiple > div {
	height: 50px !important;
	width: 50px !important;
}

.row-data:last-child {
	border: 0px;
}
.loading:after {
	content: " .";
	animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
	0%,
	20% {
		color: rgba(0, 0, 0, 0);
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	40% {
		color: white;
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	60% {
		text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	80%,
	100% {
		text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
	}
}

.editor-head {
	height: 64px;
	background-color: #d1c292;
	font-size: 13px;
	font-weight: 500;
	padding: 8px 16px;
	.head-item {
		.label {
			color: #fff;
		}
	}
}

a {
	color: var(--a-color);
	font-size: 14px !important;
	letter-spacing: 0.3px !important;
	text-decoration: none !important;
}
a:hover {
	color: var(--a-color-hover) !important;
}
a:focus {
	outline: none;
}

.rout-link {
	color: var(--a-color);
	font-size: 14px !important;
	letter-spacing: 0.3px !important;
	display: inline;
	font-weight: bold;
	line-height: normal;
}
.rout-link:hover {
	cursor: pointer;
	color: var(--a-color-hover) !important;
}

.nav-tabs .nav-link :hover {
	border: 1px;
}

.add-action-wrap {
	background: #fff;
	box-shadow: 0 -1px 4px 0 rgba(9, 9, 9, 0.07);
	color: #4898ec;
	padding: 6px 20px;
	cursor: pointer;
	.add-icon {
		font-size: 16px;
		font-weight: 700;
	}
	.add-acttion-label {
		font-weight: 600;
		font-size: 14px;
	}
}

.form-group .custom-select-box [class*="-control"] {
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid var(--page-input-border-color);
	box-shadow: none;
	height: 40px;
	font-size: 14px;
	font-weight: 500;
	color: var(--page-input-color);
}
.custom-select-box-wrap {
	width: 100%;
}
.form-group .custom-select-box [class*="-Svg"] {
	fill: #024da1;
}
.exchange-listing-page .page-header {
	height: 150px;
}
.configurations-listing-page .page-header {
	height: 150px;
}
.user-listing-page .page-header {
	height: 150px;
}
.custom-select-box [class*="-Svg"] {
	fill: #024da1;
}

.form-button-primary.inactive {
	background-color: var(--button-primary-bg-inactive) !important;
	pointer-events: none;
	color: var(--color-white) !important;
	background-image: none !important;
}
.form-button-light {
	background: #fff;
	font-size: 14px;
	color: #8a8a8a;
	padding: 6px 20px;
	height: 40px;
	border: none;
	&:hover {
		background: #fff;
		opacity: 0.7;
		color: #605f5f;
		box-shadow: none !important;
	}
	&:focus {
		background: #fff;
		opacity: 0.7;
		color: #605f5f;
		box-shadow: none !important;
		border: none;
	}
	&:active {
		background: #fff !important;
		border: none;
		color: #8a8a8a !important;
		box-shadow: none !important;
	}
}
.form-button-secondary,
.form-button-secondary:focus,
.form-button-secondary:active,
.form-button-secondary:hover {
	background-color: var(--color-white) !important;
	border: 1px solid var(--button-secondary-color) !important;
	border-radius: 4px;
	color: var(--button-secondary-color) !important;
	height: 40px;
	box-shadow: none !important;
	font-weight: 400;
}
.form-button-secondary:focus,
.form-button-secondary:active {
	border: 1px solid var(--button-secondary-color-hover) !important;
}
.form-button-secondary:hover:not(.inactive) {
	border: 1px solid var(--button-secondary-color-hover) !important;
}

.form-button-secondary.inactive {
	border: 1px solid var(--button-primary-bg-inactive) !important;
	color: var(--page-input-inactive-color) !important;
}

.form-button-secondary.small {
	height: 25px;
	font-size: 14px;
	padding-top: 0;
}
.activity-table {
	background: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
	border-radius: 5px;
}
.icon-btn {
	width: 40px;
	height: 40px !important;
	padding: 0 !important;
}

.note-button {
	background-color: #f5f9ff;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
	border-radius: 100%;
	height: 50px;
	width: 50px;
	text-align: center;
	cursor: pointer;
	position: relative;
}
.note-button .icon-notes {
	font-size: 23px;
	vertical-align: middle;
	cursor: pointer;
	position: absolute;
	top: 28%;
	left: 32%;
	padding: 0;
	margin: 0;
}
.position-fixed {
	position: fixed;
}
.sub-section-heading {
	h3 {
		color: #04080d;
		font-family: Rubik;
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;

		text-align: left;
	}
	hr {
		width: 25px;
		height: 2px;
		background-color: #5ca9ff;
		margin-top: 0px !important;
		float: left !important;
		border-top: 0;
		margin-bottom: 0px;
	}
}

.listing-actions {
	background-color: rgba(218, 231, 246, 0.31);
	// opacity: 0.31;
	border-radius: 100%;
	height: 33px;
	width: 33px;
	text-align: center;
	cursor: pointer;
	position: relative;
	.icon-more {
		opacity: 1;
	}
}
.listing-actions .icon-more {
	font-size: 22px;
	vertical-align: middle;
	cursor: pointer;
	position: absolute;
	top: 21%;
	left: 18%;
	padding: 0;
	margin: 0;
}
.link {
	color: var(--a-color) !important;
	font-size: 14px !important;
	letter-spacing: 0.3px !important;
	text-decoration: none !important;
	font-weight: 600;
	cursor: pointer;
}
.link:hover {
	color: var(--a-color-hover) !important;
}
.listing-down-arrow {
	background-color: rgba(15, 86, 166, 0.1);
	border-radius: 100%;
	height: 35px;
	width: 35px;
	text-align: center;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;

	.icon-icon-delete {
		font-size: 17px;
		&:before {
			color: #024da1 !important;
		}
	}
	.icon-down-arrow {
		font-size: 14px !important;
		color: #024da1 !important;
	}
	.icon-down-arrow,
	.icon-icon-delete {
		font-size: 16px;
		vertical-align: middle;
		cursor: pointer;
		position: absolute;
		padding: 0;
		margin: 0;
		color: #024da1;
		// color: #6c9ed8;
	}
}
.listing-actions {
	.icon-more {
		color: #024da1;
		&:before {
			color: #024da1;
		}
	}
}
.more-option-popover {
	left: -42px !important;
}
.more-option-popover .popover-body {
	border-bottom: 4px solid #024da1;
	border-radius: 5%;
	width: 105px;
}
.more-option-popover .arrow {
	transform: translate(87px, 0px) !important;
}
.more-option-popover .arrow::before {
	border-top-color: #024da1 !important;
}
.more-option-popover .arrow::after {
	border-top-color: #024da1 !important;
	top: 0px !important;
}
.more-option-popover .popover-body .more-option-item {
	color: var(--a-color) !important;
	font-size: 14px !important;
	cursor: pointer;
	padding: 0.3rem 0.3rem;
}
.more-option-popover .popover-body .more-option-item:hover {
	color: var(--a-color-hover) !important;
	background-color: rgba(45, 149, 264, 0.12);
}
.popover-body {
	padding: 0;
	margin: 0;
}
.accordion {
	margin-bottom: 10px;
	border-radius: 5%;
	.title {
		font-size: 14px !important;
	}
	.sub-title {
		font-size: 14px;
	}
	.card {
		.card-header {
			height: 55px;
			background-color: #ebf6fe;
			padding-top: 10px;
			border-radius: 7px 7px 0 0;
		}
		.card-body {
			background-color: #fff;
		}
	}
	.card:not(:last-of-type) {
		border-bottom: 1px solid rgba(104, 124, 193, 0.31) !important;
	}
	.accordian-inactive {
		&.card {
			.card-header {
				border-radius: 7px;
			}
		}
	}
}

.h1 {
	font-size: 26px !important;
	color: var(--color-white) !important;
	letter-spacing: 0 !important;
	font-weight: 700 !important;
	margin-bottom: 0 !important;
}

.h2 {
	font-size: 16px !important;
	color: var(--page-heading-color) !important;
	letter-spacing: 0 !important;
	font-weight: 700 !important;
	margin-bottom: 0 !important;
}
.label {
	color: var(--page-label-color);
	font-family: Rubik-Medium;
	font-size: 13px;
}

.w-20 {
	width: 20% !important;
}
.text {
	color: var(--page-heading-color) !important;
	font-size: 14px !important;
	letter-spacing: 0.3px !important;
	text-decoration: none !important;
}
.btn {
	padding: 6px 20px;
}
.round-button {
	background-color: #f5f9ff;
	box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
	border-radius: 100%;
	height: 40px;
	width: 40px;
	text-align: center;
	cursor: pointer;
	position: relative;
}
.round-button span {
	font-size: 23px;
	vertical-align: middle;
	cursor: pointer;
	position: absolute;
	top: 23%;
	left: 27%;
	padding: 0;
	margin: 0;
}
.full-hr {
	background: var(--heading-hr-color) !important;
	width: 100% !important;
	height: 3px !important;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	float: left !important;
	border-top: 0;
}
.user-info .user-role {
	width: 100%;
	text-align: right;
	padding-bottom: 4px;
}
.page-content {
	animation: table-anim 0.4s ease-in-out;
	padding-bottom: 0 !important;
}
.confirm-modal {
	.modal-content {
		background: #fbfbfb;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
		border-radius: 3px;
		border: none;
		padding: 20px;
		.modal-header {
			border: none;
			.heading {
				h2 {
					font-size: 14px;
					color: #232c56;
				}
			}
		}
		.modal-body {
			padding: 20px 0;
			font-size: 14px;
			color: #172843;
			letter-spacing: 0;
		}
		.confirm-footer {
			padding: 0;
			border: none;
		}
	}
}

.text {
	color: #0f4083;
}
.keyword-search {
	min-width: 320px;
}
.keyword-search .keyword-search-container {
	position: relative;
	max-width: 300px;
	width: 100%;
	float: right;
}
.keyword-search .keyword-search-container input {
	width: 100%;
	font-weight: 400;
	font-size: 12px;
	padding-right: 20px;
	padding-left: 20px;
	background: #ffffff;
	border: 1px solid rgba(0, 21, 94, 0.3);
	border-radius: 5px;
}
.keyword-search .keyword-search-container .icon {
	position: absolute;
	right: 13px;
	top: 12px;
	pointer-events: none;
	opacity: 0.5;
}
.breadcrumbs {
	color: #e1ca77;
	font-size: 12px;
}
.page-title h1 {
	margin-top: 10px;
}
.hearder-buttons {
	margin-top: 40px;
	.btn {
		font-weight: bold;
	}
}
.breadcrumbs-item {
	padding-right: 10px;
}
.breadcrumbs-link {
	cursor: pointer;
}

.comming-soon {
	// position: absolute;
	width: 95%;
	height: 375px;
	z-index: 0;
	background-image: url("./assets/images/getstarted.png");
	background-position: 100% 0;
	background-repeat: no-repeat;
	pointer-events: none;
	max-width: 95%;
	max-height: 400px;
	text-align: center;
	object-fit: contain;
	background-position: center;
}
.func-fact {
	text-align: center;
}

.logged-user-pic {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	display: inline-block;
	overflow: hidden;
}
.logged-user-pic img {
	overflow: hidden;
	object-fit: cover;
	width: 42px;
	height: 42px;
}
.user-dropdown-menu {
	//overflow: hidden;
	top: 48px;
	position: absolute;
	right: 30px;
	z-index: 1000;
	min-width: 7rem;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 7px;
	float: none;
	display: block;
	margin: 0px;
	animation: slideInDown 1s forwards;
	width: 14%;
	border-top: 4px solid #72c5e5;
	a {
		color: #172843;
	}
	.dropdown-divider {
		margin: 0;
	}
}
/*new*/
@keyframes slideInDown {
	0% {
		top: 48px;
	}
	100% {
		top: 62px;
	}
}
.user-dropdown-menu::before {
	width: 0;
	height: 0;
	content: "";
	position: absolute;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 13px solid #71c4e4;
	top: -15px;
	right: 22px;
}

/*new*/

.position-relative {
	position: relative;
}
.dropdown-divider {
	height: 0;
	margin: 0.8rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}
.modal-header {
	padding: 0;
	margin-bottom: 10px;
	margin-right: 15px;
}

.view-password-btn {
	position: absolute;
	right: 20px;
	top: 32px;
	width: 32px;
	height: 32px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 50%;
	line-height: 32px;
	cursor: pointer;
	padding-top: 1px;
	font-size: 14px;
	.password-eye {
		left: 0px !important;
	}
}
.icon-eye {
	font-size: 10px !important;
}
.newPassword-info {
	padding-left: 15px;
	color: #3594e0;
	cursor: pointer;
}
.confirm-footer {
	padding: 0.15rem;
}
.card {
	border-radius: 0.5rem !important;
}
.status-card {
	display: inline-block;
	background: rgba(218, 231, 246, 0.31);
	border-radius: 12px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	font-size: 12px;
	min-width: 80px;
	height: 30px;
	text-align: center;
}
.status-Active {
	color: #14a514;
	background: rgba(20, 165, 20, 0.12);
}
.status-New {
	color: #5ca9ff;
	background: rgba(92, 169, 255, 0.12);
}
.status-Cancelled {
	color: #d83737;
	background: rgba(216, 55, 55, 0.12);
}
.status-Closed {
	color: #969696;
	background: rgba(150, 150, 150, 0.12);
}
.status-Billed {
	color: rgb(0, 170, 99);
	background: rgba(0, 170, 99, 0.12);
}
.status-Paid {
	color: #4899f2;
	background: rgba(72, 153, 242, 0.12);
}

.no-data-list {
	margin-top: 150px;
	margin-left: 25%;
}
.no-data-list img {
	width: 400px;
	height: 150px;
}
.card-header-tabs {
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	margin-bottom: 10px;
}

.accordion > .card {
	padding: 0;
}
.form-title {
	font-size: 14px;
	font-weight: 600;
	columns: #2a3363;
}
.form-modal-header {
	border: none;
	// margin-bottom: 20px;
	padding: 20px;
	padding-bottom: 10px;
	margin: 0;

	background: #ffffff;
	color: #000;
}
.info-button {
	display: inline-block;
	box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 6px 20px;
	background: #fff;
	.label {
		color: #153371;
		font-size: 14px;
		font-family: "Rubik";
	}
	.value {
		color: #d8bb24;
		font-size: 14px;
		font-weight: 600;
	}
}
.text-highlighted {
	cursor: pointer;
	font-weight: 600;
	&:hover {
		opacity: 0.7;
	}
}
.accordion {
	.sub-title {
		font-size: 16px;
	}
}
.accordion > .card {
	padding: 0;
}
.required-label {
	color: #e74416;
	font-size: 14px;
	padding-left: 4px;
}
.exchange-listing-page .page-header {
	height: 208px;
}
.w-33 {
	width: 33% !important;
}
.w-32 {
	width: 32.7% !important;
	flex: 0 0 32.7% !important;
}

.date-picker-icon {
	position: absolute;
	float: right;
	right: 11px;
	top: 10px;
	height: 16px;
	width: 16px;
	font-size: 18px;
	cursor: pointer;
}
.date-picker-icon_2 {
	position: absolute;
	float: right;
	right: 40px;
	top: 13px;
	height: 10px;
	width: 10px;
	font-size: 11px;
	cursor: pointer;
}
.date-picker-icon_2:before {
	color: #ffffff !important;
}
.date-picker-icon_2.has-value:before {
	color: #8f8f8f !important;
}

.float-buttons {
	float: right;
}

.soon-text {
	color: #002756;
	font-size: 13px;
	font-weight: bold;
}

.advanced-filter-container {
	background: #ffffff;
	border-radius: 7px;
	border-radius: 7px;
	width: 100%;
	margin: 0;
	margin-bottom: 16px;

	.row {
		width: 100%;
	}

	.filter-form {
		width: 100%;
		padding: 15px;
		padding-top: 30px;
		padding-right: 0px;
		margin-right: 0px;
		.form-button {
			padding: 10px 20px;
			box-shadow: 0 -1px 4px 1px rgba(150, 150, 150, 0.12);
		}
		.row {
			padding-right: 0px;
			margin-right: 0px;
			margin-left: -6px;
		}
	}
	.mt-55 {
		margin-right: 25px;
	}
}
.form-button-apply {
	background: #fff;
	font-size: 14px;
	font-size: 14px;
	background: linear-gradient(to right, #3dbcf1 0%, #3386da 100%);
	-webkit-text-fill-color: rgba(51, 134, 218, 0.83);
	background-clip: text;
	//-webkit-text-fill-color: transparent;
	padding: 6px 20px;
	height: 40px;
	border: none;
	&:hover {
		background: #fff;
		opacity: 0.7;
		background: linear-gradient(to right, #3dbcf1 0%, #3386da 100%);
		background-clip: text;
		//-webkit-text-fill-color: transparent;
		-webkit-text-fill-color: rgba(51, 134, 218, 0.83);
		box-shadow: none !important;
	}
	&:focus {
		background: #fff;
		opacity: 0.7;
		background: linear-gradient(to right, #3dbcf1 0%, #3386da 100%);
		-webkit-text-fill-color: rgba(51, 134, 218, 0.83);
		background-clip: text;
		//-webkit-text-fill-color: transparent;
		box-shadow: none !important;
	}
	&:active {
		background: #fff !important;
		border: none;
		background: linear-gradient(to right, #3dbcf1 0%, #3386da 100%);
		background-clip: text;
		//-webkit-text-fill-color: transparent;
		-webkit-text-fill-color: rgba(51, 134, 218, 0.83);
		box-shadow: none !important;
	}
}
.filter-option {
	float: right;
	height: 40px;
	margin-left: 10px;
	padding-top: 10px;
	.icon-filter {
		margin-right: 15px;
	}
	.icon-excel {
		margin-right: 15px;
	}
	color: #00347b !important;
	font-weight: normal;
}
.filter-applied {
	background: rgba(0, 23, 116, 0.34);
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	border-radius: 4px;
	color: #fff !important;
	.icon-filter:before {
		color: #fff !important;
	}
}
.filter-tag {
	background: #ddedff;
	border-radius: 8px;
	border-radius: 8px;
	font-size: 13px;
	color: #00347b;
	padding: 10px;
	margin-right: 15px;
	.close-icon {
		cursor: pointer;
		width: 10px;
		height: 10px;
		font-size: 14px;
		padding-left: 14px;
		float: right;
		padding-right: 11px;
		font-weight: bold;
	}
}
.applied-filter-container {
	background: none !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.custom-toggle-btn {
	display: block;
	background-color: #cbd7d0;
	width: 28px;
	height: 15px;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	.toggle-btn {
		width: 12px;
		height: 12px;
		background-color: #fff;
		display: block;
		border-radius: 50%;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 1px;
		left: 3px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
	&.active {
		background-color: #009f92;
		.toggle-btn {
			left: 12px;
		}
	}
}
.close-btn-round {
	background: rgba(218, 231, 246, 0.31);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	cursor: pointer;
	font-size: 16px !important;
	position: absolute;
	right: 38px;
	margin-top: 7px;

	.icon-close:before {
		color: #6c9ed8 !important;
		font-size: 12px;
		top: -2px;
		position: relative;
	}
}
.icon-Notification:before {
	font-size: 18px;
}
.no-hand {
	cursor: auto !important;
}
.w-22 {
	width: 24% !important;
	flex: 0 0 24% !important;
}
.w-16 {
	width: 16% !important;
	flex: 0 0 16% !important;
}
.w-19 {
	width: 19% !important;
	flex: 0 0 19% !important;
}
.mt-25 {
	margin-top: 25px !important;
}
.mt-28 {
	margin-top: 28px !important;
}
.plr-25 {
	padding-left: 25px !important;
	padding-right: 25px !important;
}
.float-button {
	z-index: 1000;
	bottom: 150px;
	right: 15px;
}

.pd-top-15 {
	padding-top: 15px;
}
.modifiedTime {
	color: #00347b;
	font-family: Rubik-Medium !important;
	font-size: 13px;
	font-weight: 600;
	margin-right: 5px;
}
.modifiedTimeLabel {
	color: #757575;
	font-family: Rubik-Medium !important;
	font-size: 13px;
	font-weight: 500;
}
.info-section-edit {
	margin-bottom: 7px;
	margin-left: 3px;
}
.unlock-main-icon {
	background: #ffffff;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: inline-flex;
	font-size: 17px;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	cursor: pointer;
	margin-top: -5px;
	.icon-lock {
		margin-left: 1px;
		margin-bottom: 2px;
	}
}
.form-button-apply {
	font-weight: 600;
}
.react-datepicker__input-container input {
	background: var(--color-white) !important;
	&.form-disabled {
		background: #f6f9f8 !important;
	}
}
.active-black-color {
	color: var(--page-input-color);
}
.mt-20 {
	margin-top: 20px !important;
}
.accordion .card-body {
	padding-bottom: 0px !important;
}
.capitalize {
	text-transform: capitalize;
}
.fee-Billed {
	color: #00aa63 !important;
	font-weight: 700;
}
.fee-Paid {
	color: #4899f2 !important;
	font-weight: 700;
}
.dollarsIn {
	color: #4899f2 !important;
	font-weight: 700;
}
.hide-item {
	display: none !important;
}
.dollarsOut {
	color: #00aa63 !important;
	font-weight: 700;
}
.listing-actions:hover {
	opacity: 0.6;
}
.note-icon-span:hover {
	opacity: 0.6;
}
.icon-icon-edit:hover {
	opacity: 0.6;
}
.icon-notes:hover {
	opacity: 0.6;
}
.round-button:hover {
	opacity: 0.6;
}
.user-action-icon:hover {
	box-shadow: 0pc 1px 2px 1px #00000029;
	.icon-icon-edit {
		opacity: 0.6;
	}
	.icon-lock {
		opacity: 0.6;
	}

	.icon-icon-change-password {
		opacity: 0.6;
	}
}
.user-action-icon-close:hover {
	opacity: 0.6;
}
.note-button:hover {
	box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.15);
	.icon-notes {
		opacity: 0.6;
	}
}

@media screen and (min-width: 1500px) and (max-width: 2500px) {
	.summary-card-wrap {
		width: 80% !important;
	}
}

.form-button-secondary:active,
.form-button-secondary:focus,
.form-button-secondary:hover:not(.inactive) {
	border: 1px solid var(--button-secondary-color-hover) !important;
	//background-color: #0a1c96 !important;
	//color: #fff !important;
}

.icon-eye {
	position: relative;
	left: -3px;
}

.icon-doc:before {
	color: #6c9ed8 !important;
}
.icon-excel:before {
	color: #6c9ed8 !important;
}
.icon-lock:before {
	color: #6c9ed8 !important;
}
.icon-notepad:before {
	color: #6c9ed8 !important;
}
.icon-unlock:before {
	color: #6c9ed8 !important;
}

.icon-eye:before {
	color: #6c9ed8 !important;
}
.icon-eye-hide:before {
	color: #6c9ed8 !important;
}

.icon-icon-change-password:before {
	color: #6c9ed8 !important;
}
.icon-icon-delete:before {
	color: #6c9ed8 !important;
}
.icon-icon-edit:before {
	color: #6c9ed8 !important;
}

.icon-calendar:before {
	color: #6c9ed8 !important;
}

.icon-filter:before {
	color: #6c9ed8 !important;
}

.icon-more:before {
	color: #6c9ed8 !important;
}
.icon-notes:before {
	color: #6c9ed8 !important;
}
.icon-search:before {
	color: #6c9ed8 !important;
}
.no-hand {
	cursor: default !important;
}
.dashboard-no-data-card {
	max-width: 100%;
	max-height: 100%;
	text-align: center;
	object-fit: contain;
	padding-top: 150px;
}
