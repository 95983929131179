.template-page {
	.page-content {
		margin-bottom: 70px;
	}
	.status-card {
		width: 100%;
	}
	.status-702 {
		color: #4fa0fb;
		background: rgba(79, 160, 251, 0.12);
	}
	.status-701 {
		color: #e8c14b;
		background: rgba(232, 193, 75, 0.12);
	}
	.status-703 {
		color: #1ac61c;
		background: rgba(6, 198, 28, 0.12);
	}
	.spectrum-table {
		.col-bank-name {
			width: 30%;
		}
		.col-city {
			width: 10%;
		}
		.col-type {
			width: 12%;
		}
		.col-description {
			width: 15%;
		}
		.col-zip {
			width: 15%;
		}
		.col-status {
			width: 11%;
		}
		.col-actions {
			width: 10%;
		}
	}
}
.pdf-viewer {
	background-color: #fff;
}
