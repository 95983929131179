.col-notif {
	width: 70%;
}
.col-time {
	width: 20%;
}
.row_is_read {
	background-color: #f7f7f9 !important;
	color: #828282 !important;
}

.col-notif-checkbox {
	width: 60px;
	.checkmark {
		top: 5px !important;
	}
}
.filter-option {
	.icon-excel,
	.icon-icon-delete {
		margin-right: 10px;
	}
}
.action-items {
	display: flex;
	float: right;
	.action-item-delete {
		width: 120px;
	}
	.action-item-read {
		width: 165px;
	}
	.action-item-search {
		width: 322px;
	}

	.action-disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}
.spectrum-table .spectrum-table-th {
	padding: 10px 10px;
}
.custom-check {
	height: 15px !important;
	.check-input {
		height: 18px;
		width: 18px;
		top: 5px;
		left: 0;
	}
	.checkmark {
		left: 0;
		height: 18px !important;
		width: 18px !important;
	}
	span::after {
		left: 5px !important;
		top: 1px !important;
		width: 6px !important;
		height: 12px !important;
	}
}
